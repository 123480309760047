import React, { useState } from "react";
import { StaticQuery, graphql, useStaticQuery, navigate } from "gatsby";
import Modal from 'react-modal';
import { Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {isBrowser} from "../../services/auth";

const ContactForm = ({ children, seo }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [contactSource, setContactSource] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [errors, setErrors] = useState({
        name: null,
        email: null,
        message: null,
        contactSource: null,
    });

    async function submitContact(event) {

        event.preventDefault();

        let url = process.env.GATSBY_API_URL + "/contact-submissions"
        let hasErrors = false;
        let tempErrors = errors;

        //if (name == '') {
        //    tempErrors.name = true;
        //    hasErrors = true;
        //}
        //if (email == '') {
        //    tempErrors.email = true;
        //    hasErrors = true;
        //}
        //if (message == '') {
        //    tempErrors.message = true;
        //    hasErrors = true;
        //}
        //if (contactSource == '') {
        //    tempErrors.contactSource = true;
        //    hasErrors = true;
        //}
        //if (hasErrors) {
        //    setErrors(tempErrors);
        //    return;
        //}

        let data = {
            name: name,
            message: message,
            contactEmail: email,
            contactSource: contactSource
        }
        let response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if ('ok' in response) {
            if (response.ok) {
                setModalOpen(true);
                setStatusMessage('Your message has been sent. We will be in touch shortly!');
            }
            else {
                setModalOpen(true);
                setStatusMessage('There was a problem sending an email. Please try again shortly.');
            }
        }
    }


    //const data = useStaticQuery(query);

    return (
        <>
            <form id="smd-contact-form" className={"form"} onSubmit={submitContact}>
                <h1>Contact Us</h1>
                <div className="container-flex column form-row">
                    <label htmlFor={'contact_name'}>Name</label>
                    <input
                        className={errors.name === null ? "" : "form-error"}
                        type="text"
                        id={'contact_name'}
                        placeholder={'John Doe'}
                        defaultValue={name}
                        onBlur={ev => setName(ev.target.value)}
                        required={true}
                    />
                </div>
                <div className="container-flex column form-row">
                    <label htmlFor={'email'}>Email</label>
                    <input
                        className={errors.email === null ? "" : "form-error"}
                        type="email"
                        id={'email'}
                        placeholder={'johndoe@example.com'}
                        defaultValue={email}
                        onBlur={ev => setEmail(ev.target.value)}
                        required={true}
                    />
                </div>
                <div className="container-flex column form-row">
                    <label htmlFor={'message'}>Message</label>
                    <textarea
                        className={errors.message === null ? "" : "form-error"}
                        id={'message'}
                        placeholder={'How can we help you today?'}
                        defaultValue={message}
                        onBlur={ev => setMessage(ev.target.value)}
                        required={true}
                    />
                </div>
                <div className="container-flex column form-row">
                    <label htmlFor={'contact-source'}>How did you hear about us?</label>
                    <select
                        className={errors.contactSource === null ? "" : "form-error"}
                        id="contact-source"
                        defaultValue={contactSource}
                        onBlur={ev => setContactSource(ev.target.value)}
                        required={true}
                    >
                        <option value="0">Please select an option</option>
                        <option value={"WordOfMouth"}>Word of Mouth</option>
                        <option value={"GoogleAd"}>Google Ads</option>
                        <option value={"FacebookAd"}>Facebook Ads</option>
                    </select>
                </div>
                <div className="container-flex column form-row">
                    <input type="submit" value={"Send Message"} className={"uk-button uk-button-primary"}/>
                </div>
            </form>
            { statusMessage !== '' &&
            <Modal
                isOpen={modalOpen}
            >
                <header>
                    <span>Status</span>
                    <input type="button" value={'X'} onClick={ev => setModalOpen(false)}/>
                </header>
                <div className="smd-modal-body">
                    <p>{statusMessage}</p>
                </div>

                <div className="smd-modal-actions">
                    <input
                        type="button"
                        value={"Okay"}
                        className={'uk-button uk-button-primary'}
                        onClick={(ev) => {
                            setModalOpen(false);
                            setRedirect(true);
                        }}
                    />
                </div>
            </Modal>
            }

            { redirect && navigate('/') }
        </>
    );
};

//const query = graphql`
//
//`;

export default ContactForm;