import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ContactForm from "../components/contact/contact-form";

import "../assets/scss/styles.scss";

const ContactPage = () => {
    const data = useStaticQuery(query);

    return (
        <Layout seo={data.strapiHomepage.seo}>
            <ContactForm></ContactForm>
        </Layout>
    );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      homepage_cta {
        Image {
          localFile {
            publicURL
          }
        }
        Title
        Text
      }
    }
  }
`;

export default ContactPage;
